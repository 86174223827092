

























import { Component, Vue, Prop } from 'vue-property-decorator';
import BgImage from './BgImage.vue';

@Component({ components: { BgImage } })
export default class ArticleCard extends Vue {
  @Prop({ type: String, required: true })
  img!: string;

  @Prop({ type: String, required: true })
  title!: string;
}
